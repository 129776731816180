.contacts{
    width: 100%;
    height: 30vh;
    background:linear-gradient(
        125deg,
        rgba(77, 30, 3, 0.966),
        rgba(83, 32, 2, 0.986) 40%,
        rgb(78, 30, 2)
      ), url("../image/bg-dredge.jpg");
      background-attachment: fixed;
      background-size: cover;
      background-position: center;
      border-top: 1px solid  rgb(77, 30, 3, 0.966);
   }
.wrap{
    max-width: 700px;
    text-align: center;
     display: flex;
    justify-content: center;
    align-items: center;
}
.light{
    color: yellow;
}
.container-top {margin-top: 15px;
 }
.text-center{
    color: #fff;
    font-size: 15px;
}
.contacts i{
    font-size: 35px;
    margin-bottom: 10px;
    color: #fff;
}
.contacts h3{
    margin-bottom: 20px;
}
.contacts p{
    color: #fff;
    font-weight: 400;
    line-height: 1.3rem;
}
.email{
    margin-top: 30px;
}
.email a {
    padding: 8px;
    font-size: 13px;
    border-radius: 10px;
    border: 1px solid #fff;
    color: #fff;
    background-color: deeppink;

}
@media(max-width:2560px){
    .contacts{
        width: 100%;
        /* height: 20vh; */
     }
    .wrap{
        max-width: 100%;
     margin: 0px;
    }
}
@media(max-width:1440px){

    .contacts{
        width: 100%;
        /* height: 29vh; */
     }
    .wrap{
        max-width: 90%;
        margin: 0px 350px;
    }
}
@media(max-width:1024px){
    .contacts{
        width: 100%;
        /* height: 34vh; */
     }
    .wrap{
        max-width: 100%;
        margin: 0px 70px;
    }
}
@media(max-width:820px){
    .contacts {
      min-height: 200px;
    }
  }
@media(max-width:768px){
    .contacts{
        width: 100%;
      }
}
@media(max-width:425px){
    .contacts{
          height: 20vh;
      }
      .contacts i{
        font-size: 35px;
        margin-bottom: 10px;
        margin-top: 10px;
        color: #fff;
    }
    .contacts h3{
        margin-bottom: 10px;
    }
    .contacts p{
        color: #fff;
        font-weight: 400;
        line-height: 1.3rem;
    }
    .email{
        margin-top: 10px;
    }
    .email a {
         font-size: 10px;
    }
}
@media(max-width:375px){
    .contacts{
        height: 28vh;
    }
}