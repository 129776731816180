.shift{width: 100%;
    padding: 2rem;
     display: flex;
    
    margin-left: 40px;
    text-align: center;
    align-items: center;
    justify-content: center;
 }
.motionsd{
    margin-left: 100px;
    background-color: transparent;
    text-align: center;
}
.lighten{
    color: #65361B;
}
.motionsd h3{
    font-size: 50px;
    font-weight: 600;
font-style: normal;
    font-family: 'works',sans-serif;
 }
.motionsd p{
    font-size: 15px;
    letter-spacing: 1px;
}
.producted{
    width: 100%;
    text-align: center;
    display: block;
}
 .menu{
    display: grid;
    
    grid-template-columns: repeat(4,1fr);
   list-style: none;
   
   gap: 2rem;
    grid-template: 2rem;
   font-weight: 500;
   font-size: 1.3rem;
   transition: 3s all ease;

   margin-bottom: 30px;
} 
.menu ul{
    transition: 3s all ease;

}
.menu >li:hover{
    color: var(--black); 
    cursor: pointer;
    transition: 3s all ease;

}
 
.producre{
    display: block;
    color: white;
    font-family: 'montserrat',sans-serif;
    position: relative;
     display: inline-block;
     background: rgb(121, 45, 1);
    border-radius: 10px;
    margin: 5px;
    text-orientation: none;
    overflow: hidden;
    transition: 1s all ease;
}

.producre img{
    width: 45vh;
    height: 30vh;
    object-fit: cover;
    transition: transform 2s ease 0s; 
}

.overlay{
    position: absolute;
    color: white;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    /* transition: opacity 0.45s ease-in-out; */

    border-radius: 10px;
 transition: 1s all ease;
 overflow: hidden;
    box-shadow: 5PX 7PX 25px rgba(0,0,0,0.3);

}
.overlay::before{
    background: #65361B;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    transition: all 0.6s ease;
}
.btn1::before{
    width: 0;
    height: 100%;
}
.btn1:hover::before{
    width: 100%;
}
.content{
    border-radius: 10px;
    position: absolute;
    top: 45%;
    left:50%;
background:transparent;
    transform: translate(-50%,-50%);
    color: #fff;
    font-family: Verdana, sans-serif;
    text-align: center;
}
 
.content h3{
    margin-top: 30px;
    color: white;
    font-weight: bold
    ;font-size: 1.3rem;
    font-family: 'poppins',sans-serif;
}
.content a {
    width: 7rem;
    margin-top: 60px;
    cursor: pointer;
padding: 9px;
box-shadow: 5PX 7PX 25px rgba(0,0,0,0.3);
color: #fff;
border-radius: 15px;
background:#65361B;
border: 1px solid #fff;
/* opacity: 1.3; */
position: relative;
    margin-left: 30px;
    transition: all 0.5s;
    ;font-size: 1rem;

}
.producre:hover .overlay{
    opacity: 0.8;
    box-shadow: 5PX 7PX 25px rgba(0,0,0,0.3);
}
.producre:hover::before{
    width: 100%;
}
.producre a:hover{
    background-color: lightblue;
    color: #000;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);

       /* border: 1px solid saddlebrown */
}

.list {
   list-style-type: none;
   display: grid;
   grid-template-columns: repeat(4,1fr);
}

.producre ul li{
     margin: 10px;
    border-radius: 5%;
}
@media(max-width:2560px) {
    .producre{
        right: 40px;
    }
    .menu{
    margin-left: -10px;
    }
    .producre img{
        width: 40vh;
        height: 20vh;
        object-fit: cover;
        transition: transform 2s ease 0s; 
    }
}
@media(max-width:1440px) {
 
    .producre{
        right: 0px;
    }
.menu{
    width: 100%;
    max-width: 90%;
    gap: 0rem;
    text-align: center;
    margin-left: 50px;
       grid-template: 0rem;
        margin-bottom: 30px;
 }

.list {
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(4,1fr);
 }
 .producre img{
    width: 30vh;
    height: 20vh;
    object-fit: cover;
    transition: transform 2s ease 0s; 
}
}

@media(max-width:1160px) {
    .producre img{
        width: 25vh;
    }
}
    @media(max-width:1024px) {
    .producre img{
        width: 100%;
        height: 20vh;
        object-fit: cover;
        transition: transform 2s ease 0s; 
    }
    .list {
        list-style-type: none;
        display: grid;
        grid-template-columns: repeat(3,1fr);
     }
}

     @media(max-width:820px) {
        .menu{
             text-align: start;
            margin-left: 40px;
          }
        .producre img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            transition: transform 2s ease 0s; 
        }
        .list {
            list-style-type: none;
            display: grid;
            gap: 20px;
            grid-template-columns: repeat(2,3fr);
         }
     }
     @media(max-width:768px) {
     .motionsd{
         justify-content: center;
        align-items: center;
            margin-left: 0px;
        }
        .producted{
            width: 100%;
             justify-content: center;
            align-items: center;
            text-align: center;
            display: block;
        }
    .menu {
     gap: 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 0px;
        width: 100%;
 }
.menu li{
    height: 50px;
    padding: 10px;
    border: 1px solid var(--black);    
 margin: 15px;
border-radius: 5px;
background: #65361B;
color: #fff;
}
.producted img  {
    top: 0rem;
    width: 100%;
    height: 100%;
    object-position: center;
     object-fit: contain;
    transition: transform 2s ease 0s; 
    }
    .producre{
        width: 80%;
         display: flex;
        padding: 0;
        border-radius: 10px;
         padding-left: 0px;
         background: rgb(121, 45, 1);
    }
    .list {
        padding-left: 10px;
        padding-right: 10px;
       list-style-type: none;
       display: grid;
       grid-template-columns: repeat(2,1fr);
    }
    
    .producre ul li{
         padding: 0rem;
         margin: 5px;
padding-top: 4px;
     }
     .content{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;
     }
.content h3{
    color: #fff;
    font-weight: bold;
    font-size: 1.3rem;
    font-family: 'poppins',sans-serif;
}
.content a {
    width: 7rem;
    margin-top: 40px;
    cursor: pointer;
padding: 9px;
color: #fff;
border-radius: 15px;
background:#65361B;
    transition: all 0.5s;
    ;font-size: 1rem;

}   
}

@media(max-width:601px){
 
    .motionsd p{
        font-size: 11px;
        letter-spacing: 1px;
    }
    .shift{
          margin-left: 0;
     }
     .menu {
        gap: 0rem;
 display: block;
        margin-left: 40px;
           width: 100%;
     }
   .menu li{
       height: 35px;
       padding: 10px;
     margin: 10px;
    font-size: 15px;
   border-radius: 5px;
 
   }
    .content h3{
        margin-top: 15px;
        color: #000;
         ;font-size: 15px;
     }
    .content a {
        width: 5.5rem;
        margin-top: 40px;
     padding: 7px;
    background:brown;
          ;font-size: 12px;
    }
    .list {
        list-style-type: none;
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(2,3fr);
     }
     .producre{
        display: flex;
        justify-content: center;
    align-items: center;
margin-left: 40px;
}
}
@media(max-width:451px){
    .menu li{
        height: 40px;
        padding: 5px;
        border: 1px solid var(--black);    
     margin: 5px;
    border-radius: 5px;
    background: #65361B;
    color: #fff;
    }
    .list {
        padding-left: 10px;
        padding-right: 10px;
       list-style-type: none;
       display: grid;
       grid-template-columns: repeat(2,1fr);
    }
 
    .menu {
        padding: 0px;
        font-size: 18px;
    margin-left: 15px;
}
.content h3{
    margin-top: 15px;
    color: #000;
     ;font-size: 13px;
 }
 .content a {
    width: 5.5rem;
    margin-top: 45px;
 padding: 7px;
      ;font-size: 10px;
}
}
@media(max-width:425px){
    .motionsd{
        margin-left: 55px;
    }
    .menu {
         gap: 10px;
         margin-left: 30px;
    }
    .producre{
        padding: 8px;
    }
.list {
    width: 100%;
   list-style-type: none;
   display: grid;
   grid-template-columns: repeat(1,1fr);
}

.producre ul li{
    object-position: center;
   object-fit: cover;
   padding: 0;
     margin: 0px;
    border-radius: 5%;

}
.producre img{
    width: 100%;
    height: 100%;
}
.content h3 {
    color: #fff;
}

.content a {
    width: 5rem;
    margin-top: 45px;
 padding: 7px;
      ;font-size: 10px;
}
.motionsd h3{
    font-size: 34px;
    font-family: 'poppins',sans-serif;
 }
}
@media(max-width:414px){
 
    .content h3{
         color: white;
         font-size: 20px;
     }
}
@media(max-width:390px){


.content a {
    width: 5rem;
    margin-top: 30px;
  }
}
@media(max-width:375px){
    .motionsd{
        margin-left: 0px;
    }
 .menu{
    margin-left: 25px;
 }
.menu li{
 margin: 10px;
}
.producre{
    margin-left: 35px;
}
 
    .content h3{
     color: #fff;
    }
    .content a {
        width: 5rem;
      padding: 7px;
          ;font-size: 11px;
    }
}

@media(max-width:320px){
 
}
