@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
:root {
  --bg-white-900: #f2f2fC;
  --bg-white-100: #fdf9ff;
  --bg-white-50: #e8dfec;
  --text-black-900: #302e4d;
  --text-black-700: #302e4d;
}
*{  margin: 0;
    box-sizing: border-box;

    font-family: 'quicksand';
}
body{

}
ul li a{
  display: flex;
  justify-content: space-between;
  margin-right: 30px;
}
.search  {
  width: 100%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center; 
    max-width: 100%;
    /* margin: 0 auto; */
    background-color:  #341F02;
    /* border-bottom: 1px solid #007ced; */
 /* position: relative; */
}

.search h2 {
    color: #fff;
    font-size: 22px;
    padding: 10px;
    text-align: center;
    width: 45px;
    background-color: transparent;
    box-shadow: rgba(255, 255, 255, 0.644) 0px 1px 3px;
    font-style: inherit;
    /* padding: 10px; */
    border-radius: 50%;
    position: relative;
    margin-left: 10px;
}
/* .search .Link{} */
 .search a{
    margin-left: 16px;
    text-decoration: none;
  }
  .forms{
    width: 100%;
    height: 100vh;
    background:linear-gradient(220deg, #d8dfd8 30%, #636260 70%);
}
.forms form h2{
    color: brown;
    font-size: 55px;
    text-transform: capitalize;
    font-family: 'works',sans-serif;
    text-align: center;
}
 .forms form{
display: block;
padding:50px 200px;
   }
 .forms form input{
  width: 100%;
  padding: 15px 10px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  border-bottom: 1px solid #ddd;
  box-sizing: border-box;
  display: block;
   outline: none;
   margin-bottom: 30px;
   color: #C0C0C0;   
    background:#f2f2fC;
 }
 .forms form textarea{
  width: 100%;
  height: 10px;
  outline: none;
  padding: 10px;
  background: #f2f2fC;
 }
 .forms form button{
  margin-top: 40px;
   background: #341F02;
  color: #C0C0C0;
  border: 0;
  border-radius: 5px;
  padding: 12px;
   cursor: pointer;
}

.search a:hover{
  color: #f2f2f2;
} 

.data345 a {
  color: #f1356d;
  font-weight: bold;
  margin-right: 10px;

}

.data345 a .Link {
  color: #fff;
  font-weight: bold;
  transition: 0.5s;
}
 
.data345 a:hover{
  transition: 0.5s;
  color: #333;
}

.contentw{
  width: 100%;
  background: linear-gradient(to top,#301D01,#301D01),url('./image/bg-dredge.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.blog-list{
    display: flex;
   padding-left: 100px;
    align-items: center;
 }

.blog-list h2{
  font-size: 40px;
  color: #f1356d;

   
}
.errond{
  color: #f1356d;
  font-size: 30px;

}
.boyf{
  font-size: 50px;
  color: green;
  text-align: center;
}
 
.blog-preview{
  max-width: 900px;
  padding: 70px 40px;
text-align: left;
}

.blog-preview h2{
  font-size: 40px;
  color: #e8dfec;
  margin-bottom: 8px;
}
.blog-preview p {
  letter-spacing: 1px;
color: rgb(202, 199, 199);
 
font-size: 27px;
font-family: 'works',sans-serif;
 }
.blog-preview a {
  text-decoration: none;
}
.gum{
  display: flex;
  justify-content: start;
}
.gum i{
  margin: 10px;
  padding: 15px;
  font-size: 25px;
  border-radius: 10px;
  color: #f2f2f2;
  border: 1px solid #fff;
  transition: 0.9s;
  }

.gum i:hover{
  color: #007ced;
  transform: rotate(360deg);
}



.animate-text {
  width: 100%;
  display: inline-flex;
  text-indent: 5rem;
  position: relative;
  /* left: 370px; */
  bottom: 70px;
}

.animate-text span{
  animation: anim 13s linear infinite;
  color: var(--bg-white-50)!important;
  opacity: 0;
  font-weight: bold;
  overflow: hidden;
  font-size: 37px;
  position: absolute;
}

.animate-text span:nth-child(2) {
 animation-delay: 2.5s;
}
.animate-text span:nth-child(3) {
 animation-delay: 5s;
}
.animate-text span:nth-child(4) {
 animation-delay: 7.5s;
}
.animate-text span:nth-child(5) {
 animation-delay: 10s;
}

@keyframes anim {
 0% {
   opacity: 0;
 }
 5% {
   opacity: 0;
   transform: translateY(-50px);
 }
 10% {
   opacity: 1;
   transform: translateY(0px);
 }
 25% {
   opacity: 1;
   transform: translateY(0px);
 }
 30% {
   opacity: 0;
   transform: translateY(50px);
 }

 80% {
   opacity: 0;
 }

 100% {
   opacity: 0;
 }

}

@media(max-width:768px) {
body{
  width: 100%;
  /* overflow: hidden; */
}
.search h2 {
   font-size: 17px;
  padding: 10px;
   width: 40px;
}
  .content{
    width: 100%;
    /* max-width:100%; */
    height: 42vh;
    background: linear-gradient(to bottom,#301D01,#302e4d),url('./image/bg-dredge.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    /* background-color:;rgba(59, 35, 2, 0.74) */
    /* #301D01  */
  }
  .blog-preview{
    width: 100%;
    display: inline-block;
      padding: 70px 10px;
  text-align: start;
  }
  
  .blog-preview h2{
    font-size: 43px;
    color: #e8dfec;
    margin-bottom: 11.5px;
    text-indent: 11rem;
   }
  .blog-preview p {
     letter-spacing: 2px;
  color: rgb(202, 199, 199);
    line-height: 40px;
  font-size: 21.5px;
  font-weight: 600;
  padding-left: 35px;
  padding-right: 35px;
   text-align: center;
  font-family: 'works',sans-serif;
   }
   .animate-text {
    width: 100%;
    display: inline-flex;
    text-indent: 16rem;
    position: relative;
    /* left: 370px; */
    bottom: 75px;
  }
  .gum {
    display: flex;
    justify-content: center;
  }
   .gum i{
    margin-top: 30px;
    margin-left: 35px;
     padding: 15px;
    transition: 0.9s;
    }
 
}

@media(max-width:653px) {
  .blog-preview h2{
    font-size: 25px;
    text-indent: 7rem;
   }
   .animate-text {
    font-size: 25px;
    text-indent: 10rem;
    bottom: 58px;
  }
  .animate-text span {
    font-size: 25px;
  }
  .gum i{
    margin-top: 30px;
    margin-left: 20px;
     padding: 10px;
    transition: 0.9s;
    }
    .blog-preview p{
      font-size: 14px;
      text-align: left;
    }
}
@media(max-width:490px) {
  .blog-preview h2{
    font-size: 25px;
     margin-bottom: 6.8px;
    margin-left: 0px;
    text-indent: 3rem;
    letter-spacing: 2px;
  }
  .animate-text span{
    letter-spacing: 2px;
      font-size: 23px;
   }
   .blog-preview p {
    letter-spacing: 2px;
  font-size: 14px;
 font-weight: 100;
 padding-left: 5px;
 padding-right: 5px;
  text-align: start;
 font-family: 'works',sans-serif;
  }
  .gum i{
    margin-left: 10px;
    padding: 4px;
    border-radius: 5px;
  }
}
@media(max-width:452px) {
  .blog-preview h2{
    text-indent: 0;
    font-size: 20px;
     margin-left: 38px;
   }
   .animate-text span{
    letter-spacing: 2px;
      font-size: 20px;
   }
   .animate-text {
    text-indent: 6rem;
    bottom: 47px;
  }
  .blog-preview p {
 font-size: 15px;
 opacity: 1;
  }
  .h-text p{
    color: #444;
    line-height: 30px;
   font-size: 10px;
   opacity: 0.7;
   font-weight: 100;
   /* text-align: left; */
   margin-right: 0;
   margin-bottom: 20px;
 } 
}
  @media(max-width:425px) {
    
  .blog-preview h2{
    font-size: 30px;
    color: #e8dfec;
    margin-bottom: 5.5px;
    margin-left: 5vh;
  }
  .blog-preview p {
    letter-spacing: 1px;
 color: rgb(202, 199, 199);
   line-height: 1.5rem;
 font-size: 14px;
 padding-left: 0px;
 padding-right: 0px;
  text-align: center; 
  }
  .animate-text {
    width: 100%;
    display: inline-flex;
    text-indent: 5.5rem;
    bottom: 55px;
  }

   .animate-text span{
    animation: anim 13s linear infinite;
    color: var(--bg-white-50)!important;
    opacity: 0;
    font-weight: bold;
    overflow: hidden;
    font-size: 25px;
    position: absolute;
  }.gum{
    display: flex;
    justify-content: center;
  }
  .gum i{
    margin-top: 10px;
    margin-left: 15px;
     padding: 14px;
     font-size: 17px;
    transition: 0.9s;
    }
}
@media(max-width:375px) {
  .blog-preview h2{
    font-size: 25px;
    margin-bottom: 2px;
  }
  .blog-preview p {
    font-size: 12px;
    letter-spacing: 0px;
  color: rgb(202, 199, 199);
  
}
  .animate-text {
    text-indent: 5rem;
    margin-bottom: 0px;
    bottom: 44px;
  }
  .animate-text span{
    font-size: 20px;
   }
  .h-text h4{
    font-size: 10px;
  }
  .gum{
    display: flex;
    justify-content: center;
  }
}
@media(max-width:320px) {
  .blog-preview h2{
    font-size: 16px;
    margin-bottom: 18.5px;
  }
  .animate-text {
    width: 100%;
    display: inline-flex;
    text-indent: 4rem;
    align-items: center;
  }
  .animate-text span{
    animation: anim 13s linear infinite;
    color: var(--bg-white-50)!important;
    opacity: 0;
    font-weight: bold;
    overflow: hidden;
    font-size: 13.6px;
    position: absolute;
  }

}
 