.backgrounded{
    width: 100%;
    height: 100%;
    background:linear-gradient(to left, rgba(90, 54, 13, 0.959), rgba(112, 48, 5, 0.952)),url("../image/dredging.jpg") ;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.product{
    width: 100%;
    border-radius: 0;
      /* background: #f5f5f5; */
}
.product h2{
    color: #fff;
    opacity: 0.9;
}
.container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 4rem;
}
.img{
    width: 350px;
    height: 350px;
    border-radius: 50%;
    overflow: hidden;
    border: 15PX solid #444;
    box-shadow: 5PX 7PX 25px rgba(0,0,0,0.3);
}
.img img{
    height: 100%;
    width: 100%;
    transition: 0.5s;
}
.img img:hover{
    transform: scale(1.3);
}
.h-text{
     max-width: 600px;
    display: flex;
    flex-direction: column;
    text-align: center;
 
}
.h-text p{
    color: #fff;
    line-height: 1.5rem;
   font-size: 15px;
margin-top: 20px;
font-family: 'works',sans-serif;
letter-spacing: 1px;
 } 
.h-text h4{
    color: rgb(8, 35, 110);
     font-family: 'raleway',sans-serif;
     font-size: 2.5rem;
     font-style: initial;
     font-weight: 700;
text-shadow: 1px 2px 2px rgb(153, 159, 173);    }
 
 .chip{
     display: flex;
    justify-content: center;
 }
.chip button{
    cursor: pointer;
 border-radius: 25px;
 width: 120px;
 height: 50px;
    padding: 19px;
    background-color: #993203;
    opacity: 0.8;
    color: #fff;
    border: 1px solid #fff;
     font-size: 13px;
     margin-bottom: 10px;
   }
 @media(max-width:2560px) {
  
    .chip button{
        cursor: pointer;
          display: block;
         font-size: 10px;
     }
    .product{
         min-height: 50vh;
     }
     .img{
        border-radius: 50%;
        overflow: hidden;
        border: 15PX solid #444;
        box-shadow: 5PX 7PX 25px rgba(0,0,0,0.3);
    }
}
 
@media(max-width:1440px) {
    .container{
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-top: 4rem;
    margin: 20px;
    gap: 30px;
    }
    .product{
        /* height: 310vh; */
        min-height: 80vh;
     }
    
    }

@media(max-width:1024px) {
    .container{
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-top: 2rem;
    margin: 20px;
    gap: 10px;
    }
    .h-text{
        width: 100%;
        max-width: 100%;
       display: flex;
       flex-direction: column;
       text-align: center;
   }
    .product{
        height: 10vh;
        min-height: 100vh;
     }
    .img{
        width: 400px;
        height: 300px;
    }
    .chip button{
        cursor: pointer;
     border-radius: 25px;
     width: 104px;
     padding-top: 15px;
     height: 40px;
    }
}
@media(max-width:820px) {
    .container{
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-top: 4rem;
    margin: 10px;
    gap: 50px;
    }
    .product{
        width: 100%;
    height: 50vh;
        min-height: 30vh;
  }
  .h-text{
    width: 100%;
    max-width: 50%;
   display: flex;
   flex-direction: column;
   text-align: start;
 }
.h-text h4{
    font-size: 39px;
}
.chip{
    margin-top: 20px;
    margin-left: 40px;
}
}

@media(max-width:768px) {

    .product{
        width: 100%;
    height: 100%;
  }
 .product h2{
    font-size: 1.7rem;
    text-align: center;
      opacity: 0.5;
    padding-top: 20px;
    font-weight: 500;
  }
  
.img{
    width: 100%;
    height: 100%;
border-radius: 20px;
border: none;
display: flex;
justify-content: center;
box-shadow: none;
margin-left: 3vh;
}
.img img{
     border-radius: 25px;
padding: 20px;
    width: 400px;
    background-color: #444;
}
.img img:hover{
    transform: scale(1);
}
.container{
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
   }
 
 .h-text{
    margin-top: 30px;
    max-width: 100%;
    display: flex;
    margin-left: 20px;
    text-align: center;
    flex-direction: column;
}
.h-text h4{
      color: #fff;
   line-height: 50px;
   margin-right: 0;
    letter-spacing: 2px;
 }
.h-text p{
     line-height: 30px;
   font-size: 14.5px;
   margin-right: 0;
   
   margin-bottom: 40px;
   font-family:'poppins' sans-serif;
 } 
 .chip{
    display: flex;
    justify-content: center;
    margin-left: 0px;
 }
.chip button{
    cursor: pointer;
 border-radius: 25px;
  padding-top: 15px;
 height: 40px;
 
}
}


@media(max-width:701px){
    .h-text h4{
         font-family: Georgia, 'Times New Roman', Times, serif;
        font-size: 1.6rem;
        line-height: 30px;
        margin-right: 0;
        margin-left:20px;
        font-weight: bold;
        text-align: left;
    }
    .h-text p{
         line-height: 30px;
       font-size: 14px;
       opacity: 0.7;
       font-weight: 100;
       /* text-align: left; */
       margin-right: 0;
       margin-bottom: 20px;
     } 
     .img{
     border-radius: 20px;
     border: none;
    }
    .img img{
        width: 350px;
    }
}
@media(max-width:649px){
   
    .h-text h4{
         font-family: Georgia, 'Times New Roman', Times, serif;
        font-size: 1.6rem;
        line-height: 30px;
        margin-right: 0;
        margin-left:20px;
        font-weight: bold;
        max-width: 700px;
    }
    .h-text p{
         line-height: 30px;
       font-size: 14px;
       opacity: 0.7;
       font-weight: 100;
        margin-right: 0;
       margin-bottom: 20px;
     } 
}
@media(max-width:604px){
    .h-text h4{
        margin-left:10px;
         max-width: 900px;
    }
    .product{
        width: 100%;
    min-height: 135vh;
 }
 .h-text p{
   font-size: 13px;
 } 

}

@media(max-width:541px){
    .img{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
 border-radius: 10px;    
     overflow: visible;

}
.img img{
    margin-left: 40px;
    margin-right: 0;
    width: 350px;
    height: 350px;
    border-top-right-radius: 80%;
    border-top-left-radius: 80%;
 }
 .h-text p{
    line-height: 25px;
  font-size: 17px;
  letter-spacing: 1.4px;
  opacity: 0.7;
  margin-left: 0px;
  font-weight: 100;
   margin-right: 0;
  margin-bottom: 20px;
} 
}
@media(max-width:467px){
    .product{
        width: 100%;
    min-height: 87vh;
 }
    .img{
        display: none;
     }
}
@media(max-width:452px) {
    .h-text{
        margin-left: 0px;
     }
    .h-text p{
       line-height: 20px;
     font-size: 11px;
     opacity: 0.7;
     font-family: Verdana, Geneva, Tahoma, sans-serif;
     font-weight: 500;
     text-align: center;
     font-style: inherit;
     margin-right: 0;
     margin-bottom: 20px;
   } 
}
@media(max-width:425px){
  
    .product{
       min-height: 100%;
     }
     
 .h-text{
    margin-top: 10px;
    margin-left: 0px;  
}
      .h-text h4{
       width: 100%;
       font-size: 21px;
       line-height: 42px;
       text-align: start;
       }
.h-text p{
     line-height: 35px;
   font-size: 15px;
   letter-spacing: 2px;
   text-align: start;
   opacity: 0.9;
   font-weight: 100;
    margin-right: 0;
   margin-left: 3px;
   margin-bottom: 20px;
 } 
    .img{
        display: none;
    }
    .container{
          
        padding-top: 0rem;
    }
}
@media(max-width:400px){
 
     .h-text h4{
         font-size: 20px;
         letter-spacing: 1px;
        line-height: 42px;
        text-align: start;
        }
     .h-text p{
        line-height: 30px;
        font-size: 16px;
        letter-spacing: 0px;
        opacity: 0.8;
        font-weight: 600;
    } 
     
}

@media(max-width:375px){
    .product{
        /* background-color:  #341F02; */
        min-height: 74vh;
     }
    .move button{
        top: 53rem;
        left: -65px;
        margin-left: 150px;
        } 
        .h-text h4{
            line-height: 25px;
             font-size: 22px;
             font-family: 'works',sans-serif;
       } 
       .h-text p{
        line-height: 25px;
       font-size: 13.7px;
       letter-spacing: 1px;
        font-weight: 100;
       font-family: 'motion',sans-serif;
       text-align: left;
       margin-bottom: 10px;
   } 
        .product h2{
            font-size: 1.7rem;
            text-align: left;
            margin-left:25px;
             opacity: 0.5;
            padding-top: 20px;
            font-weight: 500;
          }
        .img{
            display: none;
        }
        .img img{
            display: none;
        } 
        .container{
          
            padding-top: 0rem;
        }      
}
@media(max-width:320px){
    .product{
        min-height: 85vh;
     }
    .product h2{
        font-size: 13px;
      }
      .h-text h4{
         font-family: Georgia, 'Times New Roman', Times, serif;
        font-size: 1.1rem;
        line-height: 30px;
        margin-right: 0;
    }
    .h-text p{
        line-height: 25px;
        font-size: 10.6px;
        opacity: 0.8;
        /* padding: 3px; */
        font-weight: 500;
    } 
    .chip button{
      border-radius: 5px;
     width: 60px;
     font-size: 10px;
     padding-top: 0px;
     padding: 0px;
     height: 30px;
    }
}