 
 .search{
    overflow: hidden;
transition: 0.5s;
    padding: 20px 0;
 } 
 .search .logo img{
    width: auto;
    height: 25px;  
 }

 .search .width{
    width: 20%;
 }
 .search-box{
    width: 80%;
    border: 2px solid rgba(0, 0, 0 ,0.1);
    border-radius: 50px;
 }
 .search-box i {
    width: 5%;
    text-align: center;
    font-size: 20px;
    opacity: 0.5;
    padding: 15px 20px;
    font-size: 17px;
 }
 .navlink{
 display: flex; 
   
}
.toggle{
 /* transform: translateY(50%); */
    /* margin-bottom: 90px; */
}
 .search-box input {
    width: 100%;
    padding: 10px;
 }

 ::placeholder{
    font-size: 15px;
 }
 .search-box span {
    width: 20%;
    opacity: 0.5;
    border-left: 2px solid rgba(0, 0, 0, 0.1);
 }
 

.search .f_flex {
    justify-content: end;
} 

.catgrories{
    background-color: #f6f9fc;
    padding: 0px 30px;
    border-radius: 5px;
    height: 6vh;
    margin-top: 5px;
}
.catgrories span{
    margin-right: 20px;
    font-size: 30px;
    margin-top: 6px;
}
.catgrories h4{
    font-weight: 500;
    margin-top: 12px;
}
.catgrories h4 i{
    margin-left: 10px;
}
header{
    /* height: 8vh; */
    box-shadow: -1px 15px 17px -8px rgba(0, 0, 0 ,0.1);
}
header ul {
    /* display: inline-block; */
    /* line-height: 8vh; */
}
header ul li {
    /* margin-left: 30px; */
}
header ul li a{
    transition: 0.5s;
    font-size: 15px;
    font-weight: 400;
}
header ul li a:hover{
    color: #e94560;
}
.open {
    display: none;
}
.search.active{
    height: 12vh;
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
    background-color: #7e3c1b;
    box-shadow: -1px 11px 24px -11px rgba(0, 0, 0 ,0.2);
    transition: 0.5s;
}
 


 
@media(max-width:768px) {
    
 body{
    width: 100%;
/* overflow: hidden; */

}
    .search{
        height: 100px;
        width: 100%;
        transition: 0.5s;

    }
    header ul {
        padding-top: 30px;
    }
    /* .navlink{
        display: grid;
        transition: 0.5s;
        grid-template-columns: repeat(1,1fr);
    }
    .nav-links-MobileMenu{
        position: absolute;
        display: block;
        list-style-type: none;
        left: 0;
        top: 0;
        transition: ease-in-out 3.5s;
        width: 60%;
        height: 100vh;
        background-color: #fff;
        z-index: 888;
        box-shadow: rgba(50, 50, 93 ,0.23) 0 50px 100px -20px, rgba(0, 0, 0 ,0.3) 0 30px 60px -30px;
    } */
    header ul li{
        width: 100%;
        /* transition: all 10.5s ease-in-out; */
        text-transform: uppercase;
        line-height: 70px;
    }
    header .link{
        display: none;
    }
    /* .close,
     .open{
        position: absolute;
        top: 35px;
        right: 20px; 
        color: #ff014f ;
        display: block;
        font-size: 25px;
        z-index: 999;
        font-size: 40px;
    }
    .open{
        top: 4%;
        right: 30px;
    }
    .close{
        right: 45%;
        color: #e94560 ;
        font-size: 30px;
        background: transparent;
    } 
    .close .home-btn{
        background: linear-gradient(145deg, #e2e8ec,#ffffff);
        color: #e94560;
        border-radius: 50%;
        padding: 0;
        width: 60px;
        height: 60px;
        line-height: 60px;
        transition: 0.5s;

     } */
}